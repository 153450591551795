<template>
  <st-side-page class="patient-modal-page">
    <template slot="side">
      <div class="patient-image" :style="bgImage"
           :class="{'show-options': showImageOptions}">
        <div class="patient-image-options">
          <template v-if="bgImage">
            <button class="btn btn-icon btn-gray tooltip" data-tooltip="Remover foto"
                    @click="removeImage()">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </template>
          <template v-else>
            <button class="btn btn-icon btn-gray tooltip" data-tooltip="Enviar foto"
                    @click="form.patient.image.showOptions = true">
              <fa-icon :icon="['fal', 'upload']"/>
              <input type="file" name="image" accept="image/*"
                     @change="loadImage">
            </button>
            <button class="btn btn-icon btn-gray tooltip" data-tooltip="Tirar foto"
                    @click="openWebCam" :disabled="!hasWebcam">
              <fa-icon :icon="['fal', 'camera']"/>
            </button>
          </template>
        </div>
      </div>

      <div class="text-center mt-2" v-if="form.patient.id">
        <button class="btn btn-neutral btn-block text-primary mb-1" @click="modalAlertShow = true">
          Avisos
        </button>
        <router-link
          :to="`/attendances/hospitalizations/${form.patient.id}`"
          class="btn btn-neutral btn-block text-dark mb-1"
          @click.native="hideAppointmentModal"
        >Internação</router-link>
        <router-link
          :to="`/patients/${form.patient.id}/edit`"
          class="btn btn-neutral text-dark btn-block"
          @click.native="hideAppointmentModal"
        >Cadastro</router-link>
      </div>
    </template>
    <div class="container">
      <div class="columns">
        <!-- Primary Identity -->
        <div class="column col-4 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.identity.value.$error}">
          <label for="identity" class="form-label">Documento principal</label>
          <st-input-identity
            v-model="form.patient.identity"
            :types="primaryIdentityTypes"
            @blur="$v.form.patient.identity.value.$touch()"
            @change="loadEntityPatient"
            :disabled="!canEditIdentity"
          ></st-input-identity>
          <template v-if="$v.form.patient.identity.value.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.identity.value.cpf">CPF inválido</div>
          </template>
        </div>
        <!-- Name -->
        <div class="column col-4 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.name.$error}">
          <label for="company-name"
                 :class="!form.patient.name || form.patient.name.trim().split(' ').length < 2
                 ? 'text-error' : ''" class="form-label">
            Nome <small class="text-info">[Igual ao documento]</small>
          </label>
          <input type="text" id="company-name" name="company-name"
                 v-model="form.patient.name" class="form-input"
                 @blur="$v.form.patient.name.$touch()" @change="loadEntityByNameAndDob"
                 placeholder="Nome" autocomplete="nope">
          <template v-if="$v.form.patient.name.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.name.required">Campo obrigatório</div>
          </template>
        </div>
        <!-- Birthdate -->
        <div class="column col-2 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.birthDate.$error}">
          <label :class="!form.patient.birthDate ? 'text-error' : ''"
                 class="form-label">Nascimento</label>
          <dx-input-date
            id="person-birth-date" name="person-birth-date"
            class="form-input" key="person-birth-date"
            v-model="form.patient.birthDate"
            @blur="$v.form.patient.birthDate.$touch()"
            @change="loadEntityByNameAndDob"
          />
          <template v-if="$v.form.patient.birthDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.birthDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.patient.birthDate.date">Data inválida</div>
          </template>
        </div>
        <!-- Gender -->
        <div class="column col-2 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.gender.$error}">
          <label for="person-gender" :class="!form.patient.gender ? 'text-error' : ''"
                 class="form-label">Sexo biológico</label>
          <select id="person-gender" name="person-gender"
                  v-model="form.patient.gender" class="form-select"
                  @change="$v.form.patient.gender.$touch()">
            <option value="">[Selecione]</option>
            <option value="female">Feminino</option>
            <option value="male">Masculino</option>
          </select>
          <template v-if="$v.form.patient.gender.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.gender.required">Campo obrigatório</div>
          </template>
        </div>
        <!-- Cellphone -->
        <div class="column col-3 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.cellphone.$error}">
          <label for="cellphone" class="form-label">Celular</label>
          <div class="input-group">
            <input
              type="text"
              id="cellphone"
              name="cellphone"
              v-model="form.patient.cellphone"
              class="form-input"
              autocomplete="nope"
              @blur="$v.form.patient.cellphone.$touch()"
              placeholder="(00) 0 0000-0000"
              v-mask-phone.br
            >
            <a
              :href="whatsappLink"
              class="btn input-group-btn btn-neutral btn-icon tooltip"
              data-tooltip="Enviar mensagem"
              target="_blank"
              v-if="whatsappLink"
            ><fa-icon :icon="['fab', 'whatsapp']" class="text-info"></fa-icon></a>
          </div>
          <template v-if="$v.form.patient.cellphone.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.cellphone.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.patient.cellphone.phone">Telefone inválido</div>
          </template>
        </div>
        <!-- Phone -->
        <div class="column col-3 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.phone.$error}">
          <label for="phone" class="form-label">Telefone</label>
          <input type="text" id="phone" name="phone" v-model="form.patient.phone"
                 class="form-input" @blur="$v.form.patient.phone.$touch()" maxlength="14"
                 placeholder="(00) 0000-0000"  autocomplete="nope" v-mask-phone.br>
          <template v-if="$v.form.patient.phone.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.phone.phone">Telefone inválido</div>
          </template>
        </div>
        <!-- Email -->
        <div class="column col-4 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.email.$error}">
          <label for="email" class="form-label">Email</label>
          <input type="text" id="email" name="email" v-model="form.patient.email"
                 class="form-input" @blur="$v.form.patient.email.$touch()"
                 placeholder="nome@email.com" autocomplete="nope">
          <template v-if="$v.form.patient.email.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.email.email">Email inválido</div>
          </template>
        </div>
        <!-- Civil Status -->
        <div class="column col-2 col-md-6 col-sm-12 form-group">
          <label class="form-label">Estado civil</label>
          <select id="civil-status" class="form-select"
                  v-model="form.patient.civilStatus">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in civilStatuses"
                    :value="value" :key="value">{{ text }}</option>
          </select>
        </div>
        <!-- Social Name -->
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label for="social-name" class="form-label">Nome social</label>
          <input type="text" id="social-name" v-model="form.patient.socialName"
                 class="form-input" placeholder="" autocomplete="nope">
        </div>
        <!-- Mother Name -->
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label for="mother-name" class="form-label">Nome da mãe</label>
          <input type="text" id="mother-name" v-model="form.patient.motherName"
                 class="form-input" placeholder="" autocomplete="nope">
        </div>
        <!-- Father Name -->
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label for="father-name" class="form-label">Nome do pai</label>
          <input type="text" id="father-name" v-model="form.patient.fatherName"
                 class="form-input" placeholder="" autocomplete="nope">
        </div>
        <!-- Secondary Identity -->
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label for="identity" class="form-label">Documento secundário</label>
          <st-input-identity
            v-model="form.patient.secondaryIdentity"
            :types="secondaryIdentityTypes"
          ></st-input-identity>
        </div>
        <!-- Occupation -->
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label class="form-label">Profissão</label>
          <dx-autocomplete
            v-model="profession"
            label="name"
            :source="findCbo"
            @select="setCbo"
            :debounce="800"
            :readonly="!!profession"
            placeholder="Pesquisar profissão">
            <button slot="action" @click="unsetCbo"
                    class="btn btn-action input-group-btn btn-icon"
                    :class="profession ? 'btn-gray' : 'btn-neutral'"
                    tabindex="-1">
              <fa-icon :icon="['fal', profession ? 'times' : 'search']" />
            </button>
            <template v-slot="{ item }">
              <strong class="text-primary">{{ item.name }}</strong>
            </template>
          </dx-autocomplete>
        </div>
        <!-- CNS -->
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label for="cellphone" class="form-label">CNS</label>
          <input type="text" id="cns" name="cns" v-model="form.patient.cns" v-mask-number
                 class="form-input" placeholder="Cartão nacional de saúde"
                 maxlength="20">
        </div>
        <!-- Insurance -->
        <div class="column col-5 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.insurance.plan.id.$error}">
          <label for="insurance" class="form-label">Convênio</label>
          <select id="insurance" name="insurance" class="form-select"
                  v-model="form.patient.insurance.plan.id" @change="changeInsurance"
                  @focus="getLoadPlanSettings" @blur="$v.form.patient.insurance.plan.id.$touch()">
            <option value="">[Selecione]</option>
            <option v-for="(item, i) in insurances"
                    :value="item.plan.id" :key="i">{{ item.customName }}</option>
          </select>
          <template v-if="$v.form.patient.insurance.plan.id.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.insurance.plan.id.required">
              Campo obrigatório
            </div>
          </template>
        </div>
        <!-- Insurance Record -->
        <div class="column col-4 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.insurance.record.$error}">
          <label for="insurance-record" class="form-label">Carteira</label>
          <input type="text" id="insurance-record" name="insurance-record"
                 v-model="form.patient.insurance.record" class="form-input"
                 placeholder="000000" autocomplete="nope"
                 v-mask="'AAAAAAAAAAAAAAAAAAAA'"
                 @blur="$v.form.patient.insurance.record.$touch()"
                 @focus="getLoadPlanSettings"
                 @change="changeRecord" :disabled="!form.patient.insurance.plan.id">
          <template v-if="$v.form.patient.insurance.record.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.insurance.record.minLength">
              A carteira deve ter no mínimo {{planSettings.record.digits.min}} dígitos
            </div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.patient.insurance.record.maxLength">
              A carteira deve ter no máximo {{planSettings.record.digits.max}} dígitos
            </div>
          </template>
        </div>
        <!-- Insurance Validity -->
        <div class="column col-3 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.insurance.validity.$error}">
          <label class="form-label">Validade</label>
          <dx-input-date type="text" id="insurance-validity" name="insurance-validity"
                         v-model="form.patient.insurance.validity" class="form-input"
                         @blur="$v.form.patient.insurance.validity.$touch()"
                         @focus="getLoadPlanSettings" @change="changeValidity" autocomplete="nope"
                         :disabled="!form.patient.insurance.plan.id" />
          <template v-if="$v.form.patient.insurance.validity.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.insurance.validity.date">
              Data inválida
            </div>
            <div class="form-input-hint"
                 v-else-if="!$v.form.patient.insurance.validity.minDate">
              Carteira vencida
            </div>
          </template>
        </div>
      </div>
      <div class="sync-insurances" v-if="differentInsurances">
        <span>
          Os dados do convênio no agendamento são diferentes do cadastro do paciente.
          Deseja atualizar o cadastro?
        </span>
        <button class="btn btn-primary ml-2" @click="updateInsurance">Sim</button>
      </div>
      <div class="columns">
        <div class="column col-3 col-md-6 col-sm-12 form-group"
             :class="{'has-error': $v.form.patient.address.postalCode.$error}">
          <label for="address-cep" class="form-label">CEP</label>
          <div class="input-group">
            <input type="text" id="address-cep" name="address-cep"
                   class="form-input" v-model="form.patient.address.postalCode"
                   @blur="$v.form.patient.address.postalCode.$touch()"
                   placeholder="00.000-000"
                   @change="loadAddress" v-mask-cep autocomplete="nope">
            <button class="btn btn-neutral btn-icon btn-action input-group-btn"
                    :disabled="loadingAddress" :class="{loading: loadingAddress}">
              <fa-icon :icon="['fal', 'search']"></fa-icon>
            </button>
          </div>
          <template v-if="$v.form.patient.address.postalCode.$error">
            <div class="form-input-hint"
                 v-if="!$v.form.patient.address.postalCode.cep">CEP inválido</div>
          </template>
        </div>
        <div class="column col-9 col-md-12 col-sm-12 form-group">
          <label for="address-addressLine1" class="form-label">Logradouro</label>
          <input type="text" id="address-addressLine1" name="address-addressLine1"
                 class="form-input" v-model="form.patient.address.addressLine1"
                 placeholder="Avenida Brasil, 1000" autocomplete="nope">
        </div>
        <div class="column col-3 col-md-6 col-sm-12 form-group">
          <label for="address-addressLine2" class="form-label">Complemento</label>
          <input type="text" id="address-addressLine2" name="address-addressLine2"
                 class="form-input" v-model="form.patient.address.addressLine2"
                 placeholder="casa, sobrado, loja..." autocomplete="nope">
        </div>
        <div class="column col-4 col-md-6 col-sm-12 form-group">
          <label for="address-neighborhood" class="form-label">Bairro</label>
          <input type="text" id="address-neighborhood" name="address-neighborhood"
                 placeholder="Bairro" v-model="form.patient.address.neighborhood"
                 class="form-input" autocomplete="nope">
        </div>
        <div class="column col-3 col-md-6 col-sm-12 form-group">
          <label for="address-city" class="form-label">Cidade</label>
          <input type="text" id="address-city" name="address-city"
                 class="form-input" v-model="form.patient.address.city"
                 placeholder="Cidade" autocomplete="nope">
        </div>
        <div class="column col-2 col-md-6 col-sm-12 form-group">
          <label for="address-state" class="form-label">Estado</label>
          <select id="address-state" name="address-state"
                  class="form-select" v-model="form.patient.address.state">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in states"
                    :value="value" :key="value">{{ value }}</option>
          </select>
        </div>
      </div>
      <div class="columns">
        <div class="column col-6 col-md-12 col-sm-12 form-group">
          <label class="form-label">Observações</label>
          <textarea id="notes" class="form-input notes" rows="2"
                    v-model="form.patient.notes"/>
        </div>
        <div class="column col-6 col-md-12 col-sm-12 form-group">
          <label class="form-label">Alerta</label>
          <textarea id="notes" class="form-input" rows="2"
                    v-model="form.patient.alert" />
        </div>
      </div>
    </div>

    <dx-modal
      v-model="form.patient.image.showWebCam"
      title="Tirar foto"
      size="sm"
    >
      <image-capture
        v-if="form.patient.image.showWebCam"
        :width="352"
        :height="264"
        @snapshot="webcamSnapshot"
        ref="webcamSnapshot"
      ></image-capture>

      <div slot="footer">
        <button class="btn btn-gray btn-sm mr-2"
                @click="form.patient.image.showWebCam = false">Cancelar</button>
        <button class="btn btn-primary btn-sm btn-icon btn-icon-left"
                @click="takeSnapshot" ref="btnTakeSnapshot">
          <fa-icon :icon="['fal', 'camera']"></fa-icon> Tirar foto
        </button>
      </div>
    </dx-modal>

    <modal-alert
      v-if="modalAlertShow && form.patient.id"
      :patient-id="form.patient.id"
      :patient-name="form.patient.name"
      :show="modalAlertShow"
      @close="modalAlertShow = false"
    />
  </st-side-page>
</template>

<script>
import moment from 'moment';
import { mapMutations } from 'vuex';
import formMixin from 'src/mixins/form';
import { email, required } from 'vuelidate/src/validators';
import states from 'src/data/states';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import {
  cep, cpf, date, maxDate, minDate, phone,
} from 'src/data/validators';
import ImageCapture from 'src/components/image/Capture.vue';
import { hasWebcam } from 'src/helpers/media';
import { whatsappURL } from '@/helpers/link';
import { statuses as civilStatuses } from 'src/data/civil-statuses';
import {
  CPF,
  PERSON_PRIMARY_TYPES,
  PERSON_SECONDARY_TYPES,
} from '@/data/identity-types';
import { stringify as stringifyIdentity } from '@/helpers/indentity';
import { formatPatientData } from '../../helpers';
import ModalAlert from '../../../patient/modals/Alert.vue';

export default {
  mixins: [
    formMixin,
  ],
  components: {
    ModalAlert,
    ImageCapture,
  },
  data() {
    return {
      type: 'patient',
      path: '/patients',
      loadingAddress: false,
      civilStatuses,
      insurances: [],
      states,
      modalAlertShow: false,
      planSettings: this.blankFormPlanSettings(),
      profession: null,
      appointmentInsurance: {
        id: '',
        name: '',
        record: '',
        validity: '',
        plan: {
          id: '',
          name: '',
        },
      },
      form: {
        patient: {
          canSave: false,
          notes: '',
          alert: '',
          civilStatus: '',
          socialName: '',
          profession: {
            code: '',
            name: '',
          },
        },
      },
      hasWebcam: false,
    };
  },
  validations() {
    const rules = {
      form: {
        patient: {
          name: { required },
          birthDate: { date, required, maxDate: maxDate() },
          gender: { required },
          identity: {
            value: {},
          },
          phone: { phone },
          cellphone: { phone },
          email: { email },
          insurance: {
            record: {},
            validity: {
              date,
              minDate: minDate(
                moment(this.$store.state.appointment.form.startDate).format('YYYY-MM-DD'),
              ),
            },
            plan: {
              id: { required },
            },
          },
          address: {
            postalCode: { cep },
          },
          alertAt: { date },
        },
      },
    };
    rules.nameAndDob = ['form.patient.name', 'form.patient.birthDate'];

    if (this.form.patient.identity && this.form.patient.identity.type === CPF) {
      rules.form.patient.identity.value = { cpf };
    }

    if (this.form.patient.insurance.record) {
      if (this.planSettings.record.digits.enabled) {
        rules.form.patient.insurance.record = {
          minLength: minLength(this.planSettings.record.digits.min),
          maxLength: maxLength(this.planSettings.record.digits.max),
        };
      }
    }

    return rules;
  },
  created() {
    this.appointmentInsurance = this.$store.state.appointment.form.insurance;
    this.form.patient = this.$store.state.appointment.form.patient;
  },
  mounted() {
    this.init();
  },
  watch: {
    'form.patient.canSave': function watchPatientCanSave(value) {
      if (value && !this.form.patient.id) {
        this.loadEntityByNameAndDob();
      }
    },
    'form.patient.profession.code': function watchPatientProfession(value) {
      if (value && !this.profession) {
        this.profession = this.clone(this.form.patient.profession);
      }
    },
  },
  computed: {
    primaryIdentityTypes() {
      const identity = this.form.patient.secondaryIdentity;
      return identity && identity.type
        ? PERSON_PRIMARY_TYPES.filter(type => type !== identity.type)
        : PERSON_PRIMARY_TYPES;
    },
    secondaryIdentityTypes() {
      const { identity } = this.form.patient;
      return identity && identity.type
        ? PERSON_SECONDARY_TYPES.filter(type => type !== identity.type)
        : PERSON_SECONDARY_TYPES;
    },
    canEditIdentity() {
      return !this.form.patient.id || !this.form.patient.isIdentitySaved;
    },
    bgImage() {
      let backgroundImage = null;

      if (this.form.patient.image.preview) {
        backgroundImage = `url('${this.form.patient.image.preview}')`;
      } else if (this.form.patient.imageUrl) {
        backgroundImage = `url('${this.form.patient.imageUrl}?w=200&h=200')`;
      }
      return backgroundImage ? { backgroundImage } : null;
    },
    showImageOptions() {
      if (this.form.patient.image.showOptions) {
        return true;
      }
      return this.bgImage === null;
    },
    differentInsurances() {
      const patientInsurance = this.form.patient.insurance;
      const { appointmentInsurance } = this;
      return appointmentInsurance.plan.id !== patientInsurance.plan.id
        || (appointmentInsurance.record
          && appointmentInsurance.record !== patientInsurance.record)
        || (appointmentInsurance.validity
          && appointmentInsurance.validity !== patientInsurance.validity);
    },
    whatsappLink() {
      const number = this.onlyNumbers(this.form.patient.cellphone);
      const name = this.form.patient.name.split(' ')[0];
      return number && number.length >= 10
        ? whatsappURL(number, `Olá ${name}`)
        : null;
    },
  },
  methods: {
    ...mapMutations({
      hideAppointmentModal: 'Appointment.HIDE_APPOINTMENT_MODAL',
    }),
    async init() {
      this.loadInsurances();
      this.hasWebcam = await hasWebcam();
    },
    validate() {
      this.$v.form.patient.$touch();
      return !this.$v.form.patient.$error;
    },
    findCbo(text) {
      const value = text.trim();

      const params = {
        search: value,
      };

      return this.$http.get('/services/cbos', { params })
        .then(({ data }) => data.items)
        .catch(() => {});
    },
    setCbo(data) {
      this.form.patient.profession = {
        code: data.code || '',
        name: data.name,
      };
    },
    unsetCbo() {
      this.form.patient.profession = {
        code: '',
        name: '',
      };
      this.profession = null;
    },
    loadAddress() {
      const postalCode = this.onlyNumbers(this.form.patient.address.postalCode);
      if (postalCode && postalCode.length === 8) {
        this.loadingAddress = true;
        this.$cep.get(postalCode)
          .then((data) => {
            this.form.patient.address.city = data.city;
            this.form.patient.address.state = data.state;
            this.form.patient.address.neighborhood = data.neighborhood;
            this.form.patient.address.addressLine1 = data.street;
          })
          .catch(() => {})
          .then(() => {
            this.loadingAddress = false;
          });
      }
    },
    loadImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.form.patient.image.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.form.patient.image.preview = e.target.result;
          this.form.patient.image.showOptions = false;
        };
        reader.readAsDataURL(this.form.patient.image.file);
      } else {
        this.form.patient.image.file = null;
        this.form.patient.image.showOptions = false;
      }
    },
    removeImage() {
      this.form.patient.image = this.imageFormBlank();
      this.form.patient.imageUrl = null;

      if (!this.form.patient.id) {
        return;
      }
      this.$http
        .delete(`/entities/${this.form.patient.id}/images`)
        .catch(() => {});
    },
    async openWebCam() {
      this.form.patient.image.showWebCam = true;
      this.form.patient.image.showOptions = true;
      this.$nextTick(() => this.$refs.btnTakeSnapshot.focus());
    },
    webcamSnapshot(snapshot) {
      if (snapshot !== null) {
        this.form.patient.image.file = snapshot ? snapshot.file : null;
        this.form.patient.image.preview = snapshot ? snapshot.dataURL : null;
      }
      this.form.patient.image.showWebCam = false;
      this.form.patient.image.showOptions = false;
    },
    async takeSnapshot() {
      await this.$refs.webcamSnapshot.snapshot();
    },
    updateInsurance() {
      this.form.patient.insurance = this.clone(this.appointmentInsurance);
    },
    loadEntityPatient() {
      if (!this.form.patient.identity || !this.form.patient.identity.value) {
        return;
      }

      const identity = stringifyIdentity(this.form.patient.identity);

      this.$http
        .get(`/entities/${identity}?relType=patient`)
        .then(({ data }) => this.setPatientData(data))
        .catch(() => {});
    },
    loadEntityByNameAndDob() {
      if (this.$v.nameAndDob.$invalid) return;
      const params = {
        name: this.form.patient.name,
        birthDate: this.form.patient.birthDate,
        relType: 'patient',
      };
      this.$http
        .get('/entities', { params })
        .then(({ data }) => {
          if (data.items && data.items.length) {
            this.setPatientData(data.items[0]);
          }
        })
        .catch(() => {});
    },
    loadPatientAlert() {
      this.$emit('patient-alert', {
        patientId: this.form.patient.id,
        patientName: this.form.patient.name,
        displayTo: 'appointment',
      });
    },
    setPatientData(data) {
      this.form.patient = formatPatientData(data, {
        defaultPrimaryIdentityType: this.primaryIdentityTypes[0],
        defaultSecondaryIdentityType: this.secondaryIdentityTypes[0],
      });
      this.$store.state.appointment.form.patient = this.form.patient;
      this.loadPatientAlert();
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };
      return this.apiGetInsurances(params)
        .then((data) => {
          this.insurances = data.items;
        })
        .catch(() => {
        });
    },
    apiGetInsurances(params) {
      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => data);
    },
    imageFormBlank() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
    getLoadPlanSettings() {
      if (this.planSettings.loaded === false) this.loadPlanSettings();
    },
    changeRecord() {
      if (String(this.$store.state.appointment.form.insurance.plan.id)
        === String(this.form.patient.insurance.plan.id)) {
        this.$store.state.appointment.form.insurance.record = this
          .form.patient.insurance.record;
      }
    },
    changeValidity() {
      if (String(this.$store.state.appointment.form.insurance.plan.id)
        === String(this.form.patient.insurance.plan.id)) {
        this.$store.state.appointment.form.insurance.validity = this
          .form.patient.insurance.validity;
      }
    },
    changeInsurance() {
      if (String(this.$store.state.appointment.form.insurance.plan.id)
        === String(this.form.patient.insurance.plan.id)) {
        this.form.patient.insurance
          .record = this.$store.state.appointment.form.insurance.record;
        this.form.patient.insurance
          .validity = this.$store.state.appointment.form.insurance.validity;
      } else {
        this.form.patient.insurance.record = '';
        this.form.patient.insurance.validity = '';
      }
      this.loadPlanSettings();
    },
    loadPlanSettings() {
      const insurancePlan = this.insurances.filter(
        item => item.plan.id === this.form.patient.insurance.plan.id,
      );

      this.planSettings = this.blankFormPlanSettings();
      this.planSettings.loaded = true;

      if (insurancePlan.length === 0) {
        return;
      }

      if (insurancePlan[0].plan.id) {
        this.$http.get(
          `/insurances/${insurancePlan[0].id}/plans/${insurancePlan[0].plan.id}`,
        )
          .then(({ data }) => {
            if (data.settings.general.record) {
              if (data.settings.general.record) {
                this.planSettings.record.required = data.settings.general.record.required;
                this.planSettings.record.requiredValidity = data
                  .settings.general.record.requiredValidity;
                if (data.settings.general.record.digits) {
                  this.planSettings.record.digits.enabled = data
                    .settings.general.record.digits.enabled;
                  this.planSettings.record.digits.min = data.settings.general.record.digits.min;
                  this.planSettings.record.digits.max = data.settings.general.record.digits.max;
                }
              }
            }
          })
          .catch(() => {
          })
          .then(() => {
            this.loading = false;
          });
      }
      this.$v.form.patient.insurance.$touch();
    },
    blankFormPlanSettings() {
      return {
        loaded: false,
        record: {
          required: false,
          requiredValidity: false,
          digits: {
            enabled: false,
            min: 0,
            max: 0,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/colors";

.patient-modal-page {
  &.side-page {
    margin-left: -$layout-spacing;
    margin-right: -$layout-spacing;
    .side-page-side {
      flex: 0 0 6rem;
      border-right: none;
    }
  }
  .side-page-content {
    background-color: #FFF;
  }
  .patient-image {
    margin-top: $layout-spacing;
    background: $gray-color-light center no-repeat;
    background-size: cover;
    border-radius: $border-radius;
    height: 5rem;
    width: 5rem;
    &:not(.show-options):hover {
      .patient-image-options {
        opacity: 1;
        background-color: rgba($gray-color-light, .97);
      }
    }
    &.show-options {
      .patient-image-options {
        opacity: 1;
      }
    }
  }
  .patient-image-options {
    align-items: center;
    background-color: $gray-color-light;
    border-radius: $border-radius;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    transition: all .3s ease;
    .btn {
      margin: calc($layout-spacing / 2);
      width: 25px;
      height: 25px;
      position: relative;
      svg {
        width: .7rem;
      }
      input {
        bottom: 0;
        display: block;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: $zindex-0;
      }
    }
  }
  .panel {
    background-color: #fff;
  }
  .sync-insurances {
    align-items: center;
    background-color: lighten($info-color, 40%);
    border: $info-color solid $border-width;
    border-radius: $border-radius;
    color: $body-font-color;
    display: inline-flex;
    font-size: $font-size-sm;
    padding: $layout-spacing-sm $layout-spacing;
    .btn {
      padding: $border-width-lg $layout-spacing-sm;
      height: auto;
      line-height: normal;
    }
  }
}
</style>
