<template>
  <dx-modal :title="`Replicar agendamento - ${patient.name}`" size="lg"
            :value="show" @input="close">
    <div class="modal-replicate-appointment">
      <label class="form-switch">
        <input type="checkbox" v-model="weekly" />
        <i class="form-icon"/>Replicar semanalmente
      </label>
      <template v-if="weekly">
        <div class="divider" />
        Profissional:
        <strong class="text-primary">{{ appointmentData.professional.name }}</strong><br>
        Data e hora:
        <strong>
          {{ appointmentData.startDate | date('dddd DD[ de ]MMMM[ de ]YYYY[ às ]HH:mm') }}
        </strong>
        <div class="divider" />
        <div class="columns">
          <div class="column col-6 form-group"
               :class="{'has-error': $v.weekFilters.weekDays.$error}">
            <label class="form-label">Dia(s) da semana</label>
            <div class="week-days" id="week-days">
              <label class="form-checkbox form-inline"
                     v-for="(item, i) in weekFilters.weekDays" :key="i">
                <input type="checkbox" v-model="item.selected">
                <i class="form-icon"/>{{ item.name }}
              </label>
            </div>
          </div>
          <div class="column col-2 form-group"
               :class="{'has-error': $v.weekFilters.replicate.$error}">
            <label class="form-label">Repetir</label>
            <div class="input-group">
              <input type="text"
                     class="form-input text-center"
                     v-model="weekFilters.replicate"
                     placeholder="1"
                     v-mask="'000'"
              >
              <span class="input-group-addon text-gray">vez(es)</span>
            </div>
          </div>
        </div>
        <div class="result">
          <div class="result-item" v-for="(day, i) in days" :key="i">
            <span class="chip bg-info">{{ day | date('ddd DD/MM/YY') }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="columns">
          <div class="column form-group"
               :class="{'has-error': $v.filters.scheduleId.$error}">
            <label class="form-label">Agenda</label>
            <select id="replicate-schedule" class="form-select"
                    v-model="filters.scheduleId"
                    @change="loadProfessionals">
              <option value="">[Selecione a agenda]</option>
              <option v-for="item in schedules"
                      :value="item.id" :key="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="column col-4 form-group"
               :class="{'has-error': $v.filters.professionalKey.$error}">
            <label class="form-label">Profissional</label>
            <select id="replicate-professional" class="form-select"
                    v-model="filters.professionalKey"
                    @change="setProfessional">
              <option value="">[Selecione o profissional]</option>
              <option
                v-for="item in professionals"
                :value="item.key"
                :key="item.key">
                {{ item.name }}
                <template v-if="item.specialty">({{ item.specialty.name }})</template>
              </option>
            </select>
          </div>
          <div class="column col-2 form-group">
            <label class="form-label">Exibir ocupados?</label>
            <select id="replicate-allow-fit-in"
                    class="form-select"
                    v-model="filters.allowFitIn">
              <option :value="true">Sim</option>
              <option :value="false">Não</option>
            </select>
          </div>
          <div class="column col-auto mb-2"
               style="display: flex; align-items: flex-end">
            <button class="btn btn-gray btn-icon btn-icon-left input-group-btn"
                    :disabled="loading"
                    :class="{loading}"
                    @click="load">
              <fa-icon :icon="['fal', 'search']"/> Pesquisar
            </button>
          </div>
        </div>
        <div>
          Período: <strong>{{ filters.startDate | date }} - {{ filters.endDate | date }}</strong>
        </div>
        <div class="divider" />
        <div class="columns">
          <div class="column col-7 appointment-list">
            <div class="empty mt-2" v-if="appointments.length === 0"
                 style="min-height: 300px">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h6">Agendamentos</p>
              <p class="empty-subtitle">
                Nenhum horário disponível no período.
              </p>
              <div class="load-more" v-if="hasFilters">
                <button
                  class="btn btn-primary"
                  @click="loadMore"
                  :disabled="loading"
                  :class="{loading}"
                >Carregar mais 15 dias...</button>
              </div>
            </div>
            <div class="scroll-list-wrapper" v-else>
              <div class="scroll-list">
                <div class="replicate-items" v-for="(schedule, i) in appointments" :key="i">
                  <div class="text-bold">
                    {{ schedule.date | date('DD[ de ]MMMM[ de ]YYYY[ (]dddd[)]') }}
                  </div>
                  <div class="hour-item">
                    <button class="btn hour-item-hours tooltip"
                            :class="getScheduleColor(item)"
                            v-for="(item, h) in schedule.hours" :key="h"
                            :disabled="loading || item.selected"
                            :data-tooltip="getScheduleTooltip(item)"
                            @click="selectHour(schedule, item)">
                      {{ item.startHour }}
                    </button>
                  </div>
                </div>
                <div class="load-more" v-if="hasFilters">
                  <button
                    class="btn btn-primary"
                    @click="loadMore"
                    :disabled="loading"
                    :class="{loading}"
                  >Carregar mais 15 dias...</button>
                </div>
              </div>
            </div>
          </div>
          <div class="column col-5">
            <div class="empty mt-2"
                 style="min-height: 300px"
                 v-if="selectedItems.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <p class="empty-title h6">Selecionados</p>
              <p class="empty-subtitle">
                Nenhum horário selecionado
              </p>
            </div>
            <template v-else>
              <label class="form-label tooltip"
                     data-tooltip="Não precisa informar se for o mesmo do agendamento.">
                Procedimento para os novos agendamentos
              </label>
              <dx-autocomplete
                id="expense-name"
                v-model="expense"
                :source="findExpense"
                label="name"
                track-by="id"
                @select="setExpense"
                :debounce="800"
                :readonly="isSetExpense"
                placeholder="Nome do procedimento..."
              >
                <template v-slot:action>
                  <button
                    v-if="isSetExpense"
                    @click="unsetExpense"
                    class="btn btn-action input-group-btn btn-icon btn-gray"
                    tabindex="-1">
                    <fa-icon :icon="['fal', 'times']" />
                  </button>
                  <button
                    v-else
                    class="btn btn-action input-group-btn btn-icon btn-neutral"
                    tabindex="-1">
                    <fa-icon :icon="['fal', 'search']" />
                  </button>
                </template>
                <template v-slot="{ item }">
                  <div>{{ item.name }}</div>
                </template>
              </dx-autocomplete>
              <label class="form-switch mt-2">
                <input type="checkbox" v-model="addCurrentAppointment" />
                <i class="form-icon"/>Incluir agendamento atual?
              </label>
              <label class="form-switch mt-2">
                <input type="checkbox" v-model="printSessions" />
                <i class="form-icon"/>Imprimir as sessões?
              </label>
              <div class="divider" />
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center" width="30px">#</th>
                  <th class="text-center">Data Hora</th>
                  <th>Profissional</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in selectedItems" :key="i">
                  <td class="text-center">{{ i + 1 }}</td>
                  <td class="text-center">{{ item.startDate | date('DD/MM/YYYY HH:mm') }}</td>
                  <td>{{ item.professional.name }}</td>
                  <td class="text-right">
                    <button class="btn btn-sm btn-icon btn-action btn-error"
                            @click="removeHour(item, i)">
                      <fa-icon :icon="['fal', 'times']" />
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
          </div>
        </div>
      </template>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-1"
              v-if="selectedItems.length > 0 || days.length > 0"
              :class="{loading: saving}"
              :disabled="saving"
              @click="save">
        Salvar agendamentos
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import moment from 'moment';
import { minValue, required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import { maxValue } from 'vuelidate/src/validators';

export default {
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    patient: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      expense: null,
      addCurrentAppointment: false,
      printSessions: true,
      weekly: false,
      filters: {
        allowFitIn: false,
        scheduleId: '',
        professionalKey: '',
        startDate: '',
        endDate: '',
      },
      weekFilters: {
        weekDays: [
          {
            day: 0,
            name: 'Dom',
            selected: false,
          },
          {
            day: 1,
            name: 'Seg',
            selected: false,
          },
          {
            day: 2,
            name: 'Ter',
            selected: false,
          },
          {
            day: 3,
            name: 'Qua',
            selected: false,
          },
          {
            day: 4,
            name: 'Qui',
            selected: false,
          },
          {
            day: 5,
            name: 'Sex',
            selected: false,
          },
          {
            day: 6,
            name: 'Sáb',
            selected: false,
          },
        ],
        replicate: 1,
      },
      schedules: [],
      professionals: [],
      appointments: [],
      selectedItems: [],
      days: [],
    };
  },
  async mounted() {
    await this.loadSchedules();
    await this.init();
  },
  watch: {
    weekFilters: {
      handler() {
        this.$v.weekFilters.$touch();
        if (this.$v.weekFilters.$error) {
          this.days = [];
          return;
        }

        const weekDays = this.weekFilters.weekDays
          .filter(({ selected }) => selected)
          .map(({ day }) => day);

        const startWeek = moment(this.appointmentData.startDate);

        this.days = [];
        for (let i = 1; i <= this.weekFilters.replicate; i += 1) {
          const weekDay = moment(startWeek).add(i, 'weeks').startOf('week').toDate();
          for (let n = 0; n < 7; n += 1) {
            const day = moment(weekDay).add(n, 'days');
            if (weekDays.includes(day.day())) {
              this.days.push(day.toDate());
            }
          }
        }
      },
      deep: true,
    },
  },
  validations() {
    const rules = {
      filters: {
        scheduleId: { required },
        professionalKey: { required },
      },
      weekFilters: {
        weekDays: {},
        replicate: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(100),
        },
      },
    };

    if (this.days.length === 0) {
      rules.weekFilters.weekDays = { required };
    }

    return rules;
  },
  computed: {
    ...mapState({
      appointmentData: state => state.appointment.form,
    }),
    hasFilters() {
      return this.filters.startDate
        && this.filters.endDate
        && this.filters.scheduleId
        && this.filters.professionalKey;
    },
    isSetExpense() {
      return !!(this.expense && this.expense.id);
    },
  },
  methods: {
    async init() {
      if (this.appointmentData.scheduleId) {
        this.filters.scheduleId = this.appointmentData.scheduleId;
        await this.loadProfessionals();
        if (this.appointmentData.professional) {
          const professional = this.appointmentData.professional.id;
          const { specialty } = this.appointmentData.professional;
          this.filters.professionalKey = `${professional}-${specialty}`;
        }
        if (this.filters.scheduleId && this.filters.professionalKey) {
          await this.load();
        }
      }
    },
    async load() {
      this.filters.startDate = moment().format('YYYY-MM-DD');
      this.filters.endDate = moment().add(15, 'days').format('YYYY-MM-DD');
      this.appointments = [];
      this.selectedItems = [];
      this.loadAppointments();
    },
    loadSchedules() {
      const params = {
        limit: 0,
        active: true,
      };

      this.schedules = [];

      return this.$http.get('/schedules', { params })
        .then(({ data }) => {
          this.schedules = data.items;
        })
        .catch(() => {});
    },
    loadProfessionals() {
      this.$v.filters.$reset();
      this.professionals = [];
      this.appointments = [];
      this.selectedItems = [];

      this.filters.professionalKey = '';

      if (!this.filters.scheduleId) {
        return null;
      }

      return this.$http.get(`/schedules/${this.filters.scheduleId}/professionals`)
        .then(({ data }) => {
          data.items.forEach((professional) => {
            professional.specialties
              .forEach((specialty) => {
                this.professionals.push({
                  key: `${professional.id}-${specialty.code}`,
                  id: professional.id,
                  name: professional.name,
                  specialty,
                });
                if (this.professionals.length === 1) {
                  this.filters.professionalKey = this.professionals[0].key;
                }
              });
          });
        })
        .catch(() => {});
    },
    loadAppointments() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return null;
      }

      this.loading = true;

      const professional = this.professionals
        .find(({ key }) => key === this.filters.professionalKey);

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
        scheduleId: this.filters.scheduleId,
        professionalIds: professional.id,
      };

      return this.$http
        .get('/calendar', { params })
        .then(({ data: result }) => {
          if (result.items && result.items.length > 0) {
            let items;
            if (this.filters.allowFitIn) {
              items = result.items
                .filter(item => (
                  (!item.isArrivalOrder
                    && (
                      item.status === 'available'
                      || item.status === 'scheduled'
                      || item.status === 'confirmed'
                      || item.status === 'in_attendance'
                      || item.status === 'waiting'
                      || item.status === 'finished'
                      || item.status === 'report'
                      || item.status === 'payment'
                      || item.status === 'screening'
                    )
                  )));

              if (items && items.length > 0) {
                // Horários no qual o paciente a ser replicado já está agendado
                const patientSchedules = items.filter(item => (item.patient
                  && item.patient.name === this.appointmentData.patient.name));

                if (patientSchedules && patientSchedules.length > 0) {
                  patientSchedules.forEach((patientSchedule) => {
                    // Todos os pacientes que estão agendados nos horários no qual o paciente
                    // está agendado
                    const schedules = items
                      .filter(item => (item.startDate === patientSchedule.startDate));
                    schedules.forEach((item) => {
                      const idx = items.indexOf(item);
                      items.splice(idx, 1);
                    });
                  });
                }
              }
            } else {
              items = result.items
                .filter(item => (
                  (!item.isArrivalOrder && item.status === 'available'
                  )));
            }

            this.appointments = items
              .reduce((results, item) => {
                const startDate = item.startDate.substr(0, 10);
                const startHour = item.startDate.substr(11, 5);
                const endHour = item.endDate.substr(11, 5);
                const found = this.appointments.find(({ date }) => date === startDate);

                const scheduleSelected = this.selectedItems
                  .find(selectedItem => selectedItem.startDate === `${startDate} ${startHour}`);

                const hourData = {
                  selected: !!scheduleSelected,
                  status: item.status,
                  patients: item.status === 'available' ? 0 : 1,
                  startHour,
                  endHour,
                };

                if (!found) {
                  this.appointments.push({
                    date: startDate,
                    scheduleId: item.scheduleId,
                    professional,
                    specialty: professional.specialty,
                    hours: [hourData],
                  });
                } else {
                  const foundHour = found.hours
                    .filter(hourItem => hourItem.startHour === startHour);
                  if (foundHour && foundHour.length > 0) {
                    foundHour[0].patients += 1;
                  } else {
                    found.hours.push(hourData);
                  }
                }

                return this.appointments;
              }, []);
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadMore() {
      this.filters.startDate = moment(this.filters.endDate)
        .add(1, 'days').format('YYYY-MM-DD');
      this.filters.endDate = moment(this.filters.startDate)
        .add(15, 'days').format('YYYY-MM-DD');
      this.loadAppointments();
    },
    setProfessional() {
      if (this.filters.professionalKey === '') {
        this.appointments = [];
        this.selectedItems = [];
      }
    },
    selectHour(schedule, item) {
      this.selectedItems.push({
        startDate: `${schedule.date} ${item.startHour}`,
        endDate: `${schedule.date} ${item.endHour}`,
        scheduleId: schedule.scheduleId,
        professional: schedule.professional,
        slot: item.patients !== 0,
      });
      item.selected = true;
    },
    removeHour(item, idx) {
      const itemDate = item.startDate.substr(0, 10);
      const itemHour = item.startDate.substr(11, 5);
      const foundAppointment = this.appointments
        .find(appointment => (
          appointment.professional.id === item.professional.id
          && appointment.date === itemDate
        ));

      const foundHour = foundAppointment.hours
        .find(({ startHour }) => startHour === itemHour);

      if (foundHour) {
        foundHour.selected = false;
      }

      this.selectedItems.splice(idx, 1);
    },
    close() {
      this.$emit('close');
    },
    save() {
      if (this.weekly) {
        this.saveWeekReplicate();
      } else {
        this.saveReplicate();
      }
    },
    saveWeekReplicate() {
      this.$v.weekFilters.$touch();
      if (this.$v.weekFilters.$error) {
        return null;
      }

      this.saving = true;

      return this.$http
        .get(`/appointments/${this.id}`)
        .then(({ data: appointment }) => {
          const data = {
            appointmentId: appointment.id,
            items: this.days
              .map((day) => {
                const date = moment(day).format('YYYY-MM-DD');
                const startHour = moment(this.appointmentData.startDate).format('HH:mm');
                const endHour = moment(this.appointmentData.endDate).format('HH:mm');
                return {
                  startDate: `${date} ${startHour}`,
                  endDate: `${date} ${endHour}`,
                  scheduleId: appointment.schedule.id,
                  professionalId: appointment.professional.id,
                  specialty: appointment.professional.specialty
                    ? appointment.professional.specialty.code
                    : null,
                  slot: appointment.slot,
                };
              }),
          };
          this.$http.post('/multiple-appointments', data)
            .then(() => {
              this.days = [];
              this.close();
            })
            .catch(() => {})
            .then(() => {
              this.saving = false;
            });
        });
    },
    saveReplicate() {
      if (this.selectedItems.length === 0) {
        return;
      }

      const data = {
        addCurrentAppointment: this.addCurrentAppointment,
        appointmentId: this.id,
        defaultExpense: this.expense,
        items: this.selectedItems
          .map(item => ({
            startDate: item.startDate,
            endDate: item.endDate,
            scheduleId: item.scheduleId,
            professionalId: item.professional.id,
            specialty: item.professional.specialty
              ? item.professional.specialty.code
              : null,
            slot: item.slot,
          })),
      };

      this.saving = true;

      this.$http.post('/multiple-appointments', data)
        .then(({ data: result }) => {
          if (this.printSessions) {
            this.$file.print(`/appointments/${result.bundleId}/print-sessions`)
              .catch(() => {
                this.$toast.error('Ocorreu um erro ao imprimir!');
              });
          }
          this.appointments = [];
          this.selectedItems = [];
          this.close();
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    findExpense(search) {
      const params = {
        search,
        insuranceId: this.appointmentData.insurance.id,
        planId: this.appointmentData.insurance.plan.id,
        limit: 30,
        offset: 0,
      };

      return this.$http
        .get(`/professionals/${this.appointmentData.professional.id}/expense-prices`, { params })
        .then(({ data }) => data.items
          .map(item => ({
            id: item.expense.id,
            code: item.code,
            type: item.expense.type,
            name: item.expense.name,
            specialty: item.expense.specialty,
            modality: item.expense.modality,
            quantity: 1,
            value: item.definition.particularValue,
          })));
    },
    setExpense(expense) {
      this.expense = expense;
    },
    unsetExpense() {
      this.expense = null;
    },
    getScheduleColor(item) {
      if (item.status === 'available') return item.selected ? 'bg-warning' : 'bg-secondary';
      return item.selected ? 'bg-warning' : 'bg-error';
    },
    getScheduleTooltip(item) {
      if (item.patients === 0) return 'Horário livre';
      if (item.patients === 1) return '1 paciente';
      return `${item.patients} pacientes`;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
@import '~assets/scss/mixins';
.modal-replicate-appointment {
  .result {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-row-gap: $layout-spacing;
  }
  .appointment-list {
    display: flex;
    flex-direction: column;
    height: 21.3rem;
    overflow-y: auto;
    .scroll-list-wrapper {
      flex-grow: 1;
      position: relative;
    }
    .scroll-list {
      bottom: 0;
      overflow-y: auto;
      padding-right: $layout-spacing;
      position: absolute;
      top: 0;
      width: 100%;
      @include scroll-bar();
    }
    .load-more {
      margin: $layout-spacing-lg 0;
      text-align: center;
      .btn {
        padding: 0 $layout-spacing-xl;
      }
    }
  }
  .week-days {
    border: $border-width solid $border-color-dark;
    .form-checkbox {
      margin: 1px;
    }
    border-radius: $border-radius;
    display: flex;
    font-size: $font-size-sm;
    justify-content: space-around;
    padding: 0 $layout-spacing;
  }
  .replicate-items {
    margin-bottom: $layout-spacing;
    .hour-item {
      background-color: $gray-color-ultra-light;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      margin-top: $layout-spacing-sm;
      padding: $layout-spacing;
      .hour-item-hours {
        border: 0;
        color: $light-color;
        cursor: pointer;
        font-size: $font-size-sm;
        height: auto;
        margin: $layout-spacing-sm;
        width: 3rem;
      }
    }
  }
}
</style>
